@import './src/styles/tools';

.Login {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 100vh;
}

.Login__content {
  @include container();
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.Login__logo {
  display: block;
  margin: 0 auto;
  margin-bottom: 43px;
  width: 100%;
  max-width: 350px;
  height: auto;
}

.Login__title {
  margin-bottom: 4px;
  font-size: 25px;
  text-align: center;
  color: var(--blue);
}

.Login__text {
  font-size: 15px;
  text-align: center;
  color: var(--blue);
}

.Login__form {
  @include container(425px);

  flex: 2 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-height: 380px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.Login__form__fields {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Login__form__buttons {
  text-align: center;
}

.Login__form__link {
  font-size: 14px;
  color: var(--blue);
  text-decoration: none;
}

.Login__link {
  background-color: var(--grey-light);
  padding: 18px;
  text-align: center;
  text-decoration: none;
  color: var(--blue);
  font-size: 17px;
}
