@import './src/styles/tools';

.CreatePassword {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 100vh;
}

.CreatePassword__content {
  @include container();
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.CreatePassword__logo {
  display: block;
  margin: 0 auto;
  margin-bottom: 43px;
  width: 100%;
  max-width: 350px;
  height: auto;
}

.CreatePassword__title {
  margin-bottom: 4px;
  font-size: 25px;
  text-align: center;
  color: var(--blue);
}

.CreatePassword__text {
  font-size: 15px;
  text-align: center;
  color: var(--blue);
}

.CreatePassword__form {
  @include container(425px);

  flex: 2 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-height: 380px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.CreatePassword__footer {
  background-color: var(--grey-light);
  height: 57px;
}
