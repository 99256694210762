@import './src/styles/tools';

.Toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.Toggle__label {
  font-size: 18px;
  color: var(--blue);
}

.Toggle__button {
  @include clear-button();

  flex: 0 0 54px;
  position: relative;
  width: 54px;
  height: 30px;
  border-radius: 50px;
  background: var(--blue);
  transition: background 200ms ease;

  &[data-status='true'] {
    background: var(--pink);
  }

  &::before {
    content: 'ON';
    position: absolute;
    top: 9px;
    left: 5px;
    color: #fff;
    font-weight: bold;
    font-size: 10px;
  }
  &::after {
    content: 'OFF';
    position: absolute;
    top: 9px;
    right: 3px;
    color: #fff;
    font-weight: bold;
    font-size: 10px;
  }
}

.Toggle__status {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #fff;
  transition: transform 200ms ease;

  .Toggle__button[data-status='true'] & {
    transform: translateX(calc(100% - 2px));
  }
}
