@import './src/styles/tools';

.CheckPassword {
}

.CheckPassword__check {
  margin-top: -12px;
  margin-bottom: 16px;
}

.CheckPassword__check__item {
  position: relative;
  display: block;
  font-size: 13px;
  font-style: italic;
  color: var(--blue);
  padding-left: 20px;
  margin-bottom: 1px;

  &::before {
    position: absolute;
    content: '';
    width: 13px;
    height: 13px;
    top: 0;
    left: 0;
    background: var(--grey-light);
    border-radius: 100%;
    transition: all 0.2s ease-in-out;
  }

  &.-valid {
    &::before {
      background: var(--pink);
    }
  }
}
