@mixin container($maxWidth: var(--container)) {
  position: relative;
  margin: 0 auto;
  width: 100%;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  max-width: calc(#{$maxWidth} + var(--container-padding) * 2);
  @content;
}

$breakpoints: (
  mobile: 0px,
  phablet: 426px,
  tablet: 577px,
  laptop: 769px,
  desktop: 1025px,
  desktopXL: 1260px
);

/* breakpoints */

@mixin breakpoint($minBreakpoint, $maxBreakpoint: null) {
  $min: null;
  $max: null;

  @if map-has-key($breakpoints, $minBreakpoint) {
    $min: map-get($breakpoints, $minBreakpoint);
  } @else {
    $min: $minBreakpoint;
  }

  @if map-has-key($breakpoints, $maxBreakpoint) {
    $max: map-get($breakpoints, $maxBreakpoint) - 1;
  } @else {
    $max: $maxBreakpoint;
  }

  @if $max {
    @media screen and (min-width: #{$min}) and (max-width: #{$max}) {
      @content;
    }
  } @else {
    @media screen and (min-width: #{$min}) {
      @content;
    }
  }
}

/* title */
@mixin title() {
  margin-bottom: 13px;
  color: var(--blue);
  font-size: 24px;
  text-align: center;
}

/* button reset */
@mixin clear-button($outlineRound: false, $outline: true) {
  vertical-align: top;
  font-family: inherit;
  text-align: left;
  background: none;
  line-height: normal;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  appearance: none;
  text-decoration: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  -webkit-border-radius: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  @if $outline {
    outline: none;

    &:focus-visible {
      outline: var(--pinkDark) solid 2px;
      outline-offset: 2px;
      outline-style: auto;
    }
  }

  @if $outlineRound {
    position: relative;
    outline: none;

    &:focus-visible {
      outline: none;

      &::after {
        content: '';
        position: absolute;
        top: -3px;
        left: -3px;
        width: calc(100% + 6px);
        height: calc(100% + 6px);
        pointer-events: none;
        border-radius: 50%;
        border: var(--pinkDark) solid 1px;
      }
    }
  }
}

/* input reset */
@mixin clear-input {
  border: none;
  vertical-align: top;
  font-family: inherit;
  text-align: left;
  background: none;
  line-height: normal;
  border: none;
  padding: 0;
  margin: 0;
  appearance: none;
  outline: none;
  text-decoration: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  -webkit-border-radius: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* input */

@mixin input {
  @include clear-input();

  width: 100%;
  padding: 12px 10px;
  font-size: 16px;
  color: var(--blue);
  background-color: var(--grey-light);

  &.-white {
    background-color: #fff;
  }

  &::placeholder,
  &.-placeholder {
    color: var(--grey);
  }

  &.-error {
    background-color: var(--red-lighten);
  }
}

@mixin inputIcon() {
  display: block;

  flex: 1 0 41px;
  width: 41px;
  background-color: var(--blue);
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 6px 0 0 6px;

  &.-euro {
    background-image: url('./inputIcons/euro.svg');
  }
  &.-pen {
    background-image: url('./inputIcons/pen.svg');
  }
  &.-phone {
    background-image: url('./inputIcons/phone.svg');
  }
  &.-user {
    background-image: url('./inputIcons/user.svg');
  }
  &.-pin {
    background-image: url('./inputIcons/pin.svg');
  }
  &.-house {
    background-image: url('./inputIcons/house.svg');
  }
  &.-password {
    background-image: url('./inputIcons/lock.svg');
  }
  &.-email {
    background-image: url('./inputIcons/enveloppe.svg');
  }
  &.-justice {
    background-image: url('./inputIcons/justice.svg');
  }
  &.-paw {
    background-image: url('./inputIcons/paw.svg');
  }
  &.-date {
    background-image: url('./inputIcons/date.svg');
  }
  &.-fingerPrint {
    background-image: url('./inputIcons/fingerPrint.svg');
  }
}

/* button */

$buttonTypes: (
  large: (
    padding: 14px 40px 14px 40px
  ),
  // small: (
  //   padding: 5px 35px 5px 35px
  // )
);
@mixin button($type: large) {
  @include clear-button();

  position: relative;
  display: inline-block;
  text-align: center;
  font-size: 18px;
  background: var(--pink);
  color: #fff;
  border-radius: 6px;
  padding: map-get(map-get($buttonTypes, $type), padding);
  margin-bottom: 5px;
  transition: background 300ms ease, opacity 300ms ease, color 300ms ease;

  &:hover {
    background: var(--pink-medium);
  }

  &:focus-visible::after {
    content: '';
    pointer-events: none;
    position: absolute;
    top: -4px;
    left: -4px;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    border-radius: 9px;
    border: 2px solid var(--pink);
  }

  &:active {
    background: var(--pink-darken);
  }

  &:disabled {
    background: var(--pink);
    cursor: default;
    opacity: 0.3;
    color: rgba(white, 0.5);
  }
}

$linkStyle: (
  dark: (
    color1: var(--black),
    color2: var(--black)
  ),
  light: (
    color1: #fff,
    color2: #fff
  )
);

@mixin link($color: dark) {
  display: block;
  color: map-get(map-get($linkStyle, $color), color1);
  font-weight: bold;
  text-decoration: none;
  margin-top: auto;
  margin-bottom: 0;
  margin-right: 15px;

  &::after {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-right: solid 2px map-get(map-get($linkStyle, $color), color2);
    border-bottom: solid 2px map-get(map-get($linkStyle, $color), color2);
    margin-left: 7px;
    margin-right: -15px;
    margin-bottom: 1px;
    transform: rotateZ(-45deg);
  }
}

@mixin link-more() {
  display: block;
  color: var(--blue);
  text-decoration: none;
  // margin-top: auto;
  // margin-bottom: 0;
  // margin-right: 15px;
  white-space: nowrap;

  &::after {
    content: '';
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-left: 7px;
    background: url('/img/arrow.svg') no-repeat center;
    background-size: contain;
    // margin-right: -15px;
    margin-bottom: -2px;
    // transform: rotateZ(-45deg);
  }
}

/* wysiwyg */
@mixin wysiwyg($color: var(--black), $size: 14px) {
  counter-reset: section;
  font-size: 14px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 25px;
    margin-bottom: 20px;
    font-weight: bold;
    color: $color;
  }

  p {
    color: $color;
    font-size: $size;
    line-height: 1.36;
    margin-bottom: 20px;

    &:empty {
      margin-bottom: 14px;
    }
  }

  a {
    color: $color;
  }

  ol {
    margin-bottom: 20px;
    li {
      position: relative;
      color: $color;
      font-size: $size;
      line-height: 1.36;

      &::before {
        counter-increment: section;
        content: counter(section);
        display: inline-block;
        margin-right: 20px;
      }
    }
  }

  ul {
    margin-bottom: 20px;
    li {
      position: relative;
      color: $color;
      font-size: $size;
      line-height: 1.36;

      &::before {
        content: '•';
        margin-right: 20px;
      }
    }
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 10px auto;
  }

  @content;
}
