@import './src/styles/tools';

/* menu */
.Menu {
  z-index: 8;
  position: fixed;
  height: 100vh;
  width: 100vw;
  padding: 56px 0px;
  transition: visibility 300ms ease, opacity 300ms ease;

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
  }

  @include breakpoint(laptop) {
    display: none;
  }

  &.-desktop {
    @include breakpoint(laptop) {
      display: block;
      position: sticky;
      width: 100%;
      height: auto;
      top: 0;
      opacity: 1;
      visibility: initial;
    }
  }
}

.Menu__back {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(49, 36, 105, 0.5);

  .-desktop & {
    @include breakpoint(laptop) {
      position: relative;
      background: none;
    }
  }
}

.Menu__ul {
  position: relative;
  width: calc(100% - var(--container-padding));
  background-color: var(--blue);
  border-radius: 0 0 6px 6px;
  transition: transform 300ms ease;

  .Menu[aria-hidden='true'] & {
    transform: translateX(-100%);
  }

  .-desktop & {
    @include breakpoint(laptop) {
      background: none;
    }
  }

  .Menu[aria-hidden='true'].-desktop & {
    @include breakpoint(laptop) {
      transform: none;
    }
  }
}

.Menu__ul {
  padding: 5px var(--container-padding) 0px var(--container-padding);

  .-desktop & {
    @include breakpoint(laptop) {
      width: 100%;
    }
  }
}

.Menu__link {
  @include clear-button();
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  padding-bottom: 10px;
  color: #fff;
  font-size: 24px;
  text-decoration: none;
  border-bottom: 1px solid #fff;

  .-desktop & {
    @include breakpoint(laptop) {
      font-size: 24px;
      color: var(--blue);
      border-bottom: 1px solid var(--grey-medium);

      &.-current {
        color: var(--pink);
      }
    }
  }

  li:last-child & {
    border: none;
  }
}
.Menu__li__icon {
  flex: 0 0 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
  border-radius: 50%;
  margin-right: 15px;
  overflow: hidden;

  &:empty {
    background-image: url('/img/camera.svg');
    background-size: 50%;
  }

  &.-plug {
    background-image: url('/img/plug.svg');
    background-size: 130%;
  }

  .-desktop .-current & {
    background-color: var(--blue);
  }

  .-desktop .-current & path,
  .-desktop .-current & line,
  .-desktop .-current & rect,
  .-desktop .-current & polyline {
    stroke: white !important;
    fill: transparent !important;
  }

  @include breakpoint(laptop) {
    width: 56px;
    height: 56px;
  }
}

.Menu__li__svg {
  width: 60%;
}

.Menu__li__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
