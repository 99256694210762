@import './src/styles/tools';

.ForgetPassword {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 100vh;
}

.ForgetPassword__back {
  display: block;
  height: 40px;
  width: 40px;
  margin: 5px;
  background: url('/img/arrow.svg') no-repeat center;
  background-size: 10px;
  transform: rotateZ(180deg);
}

.ForgetPassword__content {
  @include container();
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.ForgetPassword__logo {
  display: block;
  margin: 0 auto;
  margin-bottom: 43px;
  width: 100%;
  max-width: 350px;
  height: auto;
}

.ForgetPassword__title {
  margin-bottom: 4px;
  font-size: 25px;
  text-align: center;
  color: var(--blue);
}

.ForgetPassword__text {
  margin: 0 auto;
  max-width: 270px;
  font-size: 15px;
  text-align: center;
  color: var(--blue);
}

.ForgetPassword__form {
  @include container(425px);

  flex: 2 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-height: 380px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.ForgetPassword__form__fields {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ForgetPassword__form__buttons {
  text-align: center;
}

.ForgetPassword__form__link {
  font-size: 14px;
  color: var(--blue);
  text-decoration: none;
}

.ForgetPassword__footer {
  background-color: var(--grey-light);
  height: 57px;
}
