@import './src/styles/tools';

.OwnerAccount {
  @include container();

  padding-top: 15px;
  padding-bottom: 15px;
}

/* header */
.OwnerAccount__header {
  margin-bottom: 20px;
}

.OwnerAccount__header__title {
  @include title();
}

.OwnerAccount__content {
}

.OwnerAccount__text {
  color: var(--blue);
  font-size: 18px;
  margin-bottom: 10px;
}
