@import './src/styles/tools';

.Charte {
  margin-bottom: 47px;
}

.Charte__label {
  color: var(--blue);
  text-align: center;
  font-size: 15px;
  max-width: 85%;
  margin: auto;
  margin-bottom: 23px;
}

.Charte__content {
  display: flex;
  margin-bottom: 23px;
  overflow: hidden;
  border-radius: 6px;
  background-color: var(--grey-light);
}

.Charte__content__icon {
  @include inputIcon();
}

.Charte__content__text {
  display: flex;
  max-height: 250px;
  padding: 15px;
}

.Charte__content__text__scroll {
  overflow: auto;
  height: 100%;
  color: var(--blue);
  font-size: 16px;
  line-height: normal;
  padding-right: 15px;
}

.Charte__content__status {
  position: relative;
  height: 100%;
  width: 3px;
  flex: 1 0 3px;
  background: var(--grey-medium);

  span {
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 100%;
    max-height: 100%;
    background-color: var(--blue);
  }
}

.Charte__statusLabel {
  display: flex;
  align-items: center;
  color: var(--blue);
  font-size: 15px;
  max-width: 85%;
  margin: auto;
}

.Charte__statusLabel__status {
  display: block;
  flex: 0 0 29px;
  margin-right: 10px;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  border: 1px solid var(--blue);

  &.-allowed::after {
    content: '';
    display: block;
    margin: 6px 0px 0px 5px;
    width: 17px;
    height: 10px;
    border-bottom: 1px solid var(--blue);
    border-left: 1px solid var(--blue);
    transform: rotate(-45deg);
  }
}

.Charte__content__text__scroll__wrap {
  h2 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 40px;
  }
  p {
    margin-bottom: 20px;
  }
}
