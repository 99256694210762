@import './src/styles/tools';

.AdminAccount {
  @include container();

  padding-top: 15px;
  padding-bottom: 15px;
}

/* header */
.AdminAccount__header {
  margin-bottom: 20px;
}

.AdminAccount__header__title {
  @include title();
}

.AdminAccount__content {
}

.AdminAccount__text {
  color: var(--blue);
  font-size: 18px;
  margin-bottom: 10px;
}
