@import './src/styles/tools';

.OwnerHome {
  @include container();

  padding-top: 15px;
}

.OwnerHome__title {
  @include title();
}

.OwnerHome__content {
  padding: 16px;
  background-color: var(--grey-light);
  border-radius: 5px;
  color: var(--blue);
}

.OwnerHome__hr {
  margin-top: 15px;
  margin-bottom: 15px;
  height: 1px;
  border: none;
  background: var(--grey-medium);
}

.OwnerHome__text {
  margin-bottom: 7px;
  font-size: 15px;
  line-height: 1.33;
}

.OwnerHome__restACharge {
  margin-bottom: 7px;
  color: var(--pink);
  font-size: 17px;
  font-weight: bold;
}

.OwnerHome__status {
  @include button();

  cursor: default;

  width: 100%;
  background-color: var(--blue);

  &:hover {
    background: var(--blue);
  }
}
