@import './src/styles/tools';

.DetailFolderAdmin {
  position: relative;
}

.DetailFolderAdmin__edit {
  position: absolute;
  top: 0;
  right: 0;
}

.DetailFolderAdmin__edit__img {
  width: 30px;
}

.DetailFolderAdmin__title {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 7px;
  text-transform: capitalize;
}

.DetailFolderAdmin__group {
  margin-top: 5px;
}

.DetailFolderAdmin__comment {
  display: block;
}

.DetailFolderAdmin__buttons {
  margin: auto;
  padding-top: 30px;
  max-width: 500px;
}

.DetailFolderAdmin__buttons__group {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -5px;
  margin-right: -5px;
}

.DetailFolderAdmin__button {
  @include button();
  width: 50%;
  padding-left: 28px;
  padding-right: 28px;
  margin: 0 5px 10px 5px;
}

.DetailFolderAdmin__button2 {
  @include button();
  width: 100%;
  padding-left: 28px;
  padding-right: 28px;
}
