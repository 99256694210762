@import './src/styles/tools';

.OwnerFolder {
  @include container();

  padding-top: 15px;
}

.OwnerFolder__title {
  @include title();
}

.OwnerFolder__group {
  padding-top: 15px;
  padding-bottom: 25px;
}

.OwnerFolder__text {
  line-height: 1.5;
  margin-bottom: 20px;
  color: var(--blue);
}
.OwnerFolder__link {
  color: var(--blue);
  text-decoration: none;
  font-weight: bold;
}
