@import './src/styles/tools';

.PopinMedia {
  @include clear-button();

  margin-right: 10px;
  margin-bottom: 10px;
  width: 74px;
  height: 74px;
  overflow: hidden;
  border-radius: 50%;
}

.PopinMedia__popin {
  display: block;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 50px);
  object-fit: contain;
}

.PopinMedia__img {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  object-fit: cover;
}
