@import './src/styles/tools';

.MemberHome {
  @include container();

  padding-top: 15px;
}

.MemberHome__title {
  @include title();
}

.MemberHome__more {
  @include link-more();

  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.MemberHome__figures {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: var(--blue);
}

.MemberHome__figure {
  width: 180px;
  // width: 100%;
  flex-direction: column;
  display: flex;
  // justify-content: center;
  align-items: center;
}

.MemberHome__figure__group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 115px;
  height: 115px;
  background-color: var(--grey-light);
  border-radius: 50%;
  margin-bottom: 10px;
}

.MemberHome__figure__img {
  display: block;
  margin-bottom: 10px;
}

.MemberHome__figure__number {
  font-size: 18px;
  color: var(--pink);
}

.MemberHome__figure__label {
  text-align: center;
  // position: absolute;
}
