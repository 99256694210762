@import './src/styles/tools';

.Loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.-background {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.Loading__svg {
  .Loading.-small & {
    width: 40px;
  }
}

.Loading__svg path {
  fill: var(--blue);

  .Loading.-small & {
    width: 20px;
  }

  .Loading.-white & {
    fill: #fff;
  }
}
