@import './src/styles/tools';

.DownloadMedia {
}

.DownloadMedia__link {
  display: block;
  color: var(--blue);
  margin-bottom: 7px;
  font-size: 15px;
  line-height: 1.33;
  font-weight: bold;
  text-decoration: none;

  &::before {
    content: '';
    display: inline-block;
    width: 26px;
    height: 12px;
    background: url('/styles/inputIcons/link.svg') no-repeat center;
    background-size: contain;
    margin-right: 7px;
  }
}

.DownloadMedia__download {
  display: block;
  color: var(--blue);
  margin-bottom: 7px;
  font-size: 15px;
  line-height: 1.33;
  font-weight: bold;
  text-decoration: none;

  &::before {
    content: '';
    display: inline-block;
    width: 26px;
    height: 12px;
    background: url('/styles/inputIcons/paperclip.png') no-repeat center;
    background-size: contain;
    margin-right: 7px;
  }
}
