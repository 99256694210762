@import './src/styles/tools';

.DetailFolderMember {
}

.DetailFolderMember__title {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 7px;
  text-transform: capitalize;
}

.DetailFolderMember__date {
  font-size: 15px;
  margin-bottom: 14px;
  color: var(--grey-medium);
}

.DetailFolderMember__hr {
  margin-top: 15px;
  margin-bottom: 15px;
  height: 1px;
  border: none;
  background: var(--grey-medium);
}

.DetailFolderMember__group {
  margin-top: 5px;
}

.DetailFolderMember__text {
  margin-bottom: 7px;
  font-size: 15px;
  line-height: 1.33;
}

.DetailFolderMember__medias {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 7px;
}

.DetailFolderMember__comment {
  display: block;
}

.DetailFolderMember__subtitle {
  margin-bottom: 7px;
  font-size: 18px;
  color: var(--pink);
}
