@import './src/styles/tools';

.Layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

/* header */
.Layout__header {
  z-index: 9;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  background-color: var(--blue);

  @include breakpoint(laptop) {
    position: relative;
  }
}

.Layout__header__menu {
  @include clear-button();
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 26px;
  height: 20px;

  @include breakpoint(laptop) {
    display: none;
  }

  span {
    display: block;
    width: 100%;
    height: 1px;
    background-color: #fff;
  }
}

.Layout__header__logo {
  display: none;

  @include breakpoint(laptop) {
    display: block;
    width: 100%;
    padding: 21px 0;
    text-align: center;
  }
}

.Layout__header__param {
  @include clear-button();
}

/* content */
.Layout__content {
  flex: 1 0 100%;
  height: auto;

  @include breakpoint(laptop) {
    display: flex;
  }
}

.Layout__content__left {
  display: none;

  @include breakpoint(laptop) {
    display: block;
    flex: 1 0 35%;
    width: auto;
    max-width: 380px;
    background: var(--grey-light);
  }
}

.Layout__content__right {
  width: 100%;
  min-width: 0;

  @include breakpoint(laptop) {
    flex: 1 0 65%;
    width: auto;
    padding: 30px 0;
  }
}

/* footer */
.Layout__footer {
  z-index: 9;
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-color: var(--grey-light);

  @include breakpoint(laptop) {
    position: relative;
  }

  &.-mobile {
    @include breakpoint(laptop) {
      display: none;
    }
  }

  &.-desktop {
    display: none;

    @include breakpoint(laptop) {
      display: block;
      padding: 24px 0;
      background: var(--blue);
    }
  }
}

.Layout__footer__link {
  display: block;
  text-align: center;
  width: 100px;
  font-size: 10px;
  text-decoration: none;
  color: var(--blue);
}

.Layout__footer__icon {
  display: block;
  margin: auto;
  height: 31px;
}
