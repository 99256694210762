@import './src/styles/tools';

.Tabs {
  margin-bottom: 30px;
  overflow: hidden;
}

.Tabs__wrap {
  padding: 1px 0;
  margin: -1px 0;
}

.Tabs__buttons {
  position: relative;
  display: flex;
  overflow: hidden;
  border-radius: 6px;
  background-color: white;
  margin-bottom: 17px;

  &::before {
    content: '';
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    box-shadow: inset 0 0 0px 1px var(--grey-medium);
    border-radius: 6px;
  }
}

.Tabs__button {
  @include clear-button();

  width: 100%;
  padding: 10px;
  text-align: center;
  color: var(--grey-medium);

  &:focus-visible {
    outline: none;
    background-color: var(--pink-light);
    color: #fff;
  }
}
div.Tabs__button {
  cursor: auto !important;
}

.Tabs__current {
  pointer-events: none;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--pink);
  transition: transform 300ms ease;
}

.Tabs__current__label {
  @extend .Tabs__button;

  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  color: #fff;
  transition: transform 300ms ease;
}
