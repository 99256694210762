@import './src/styles/tools';

.Link {
  &.-icon::before {
    content: '';
    display: inline-block;
    width: 37px;
    height: 14px;
    vertical-align: middle;
    background: url('/styles/inputIcons/link.svg') no-repeat center;
    background-size: contain;
  }
}
