@import './src/styles/tools';

.Error {
  padding: 15px;
  border: 1px solid var(--red);
  border-radius: 3px;
  background-color: rgba(var(--red), 0.1);
  font-size: 15px;
  color: var(--red);
}
