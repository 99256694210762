@import './src/styles/tools';

.LinkFolder {
  @include clear-button();

  position: relative;
  text-align: center;
}

.LinkFolder__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 10px auto;
  width: 66px;
  height: 66px;
  border-radius: 50%;
  background: var(--grey-light);

  .LinkFolder.-current & {
    background: var(--blue);
  }
}

.LinkFolder__svg {
  .LinkFolder.-current & path {
    stroke: #fff;
  }
}

.LinkFolder__title {
  color: var(--blue);
  font-size: 14px;

  b {
    display: block;
  }

  .LinkFolder.-current & {
    color: var(--pink);
  }
}

.LinkFolder__notification {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  font-size: 9px;
  color: #fff;
  background: var(--red);
}
