@import './src/styles/tools';

.Modal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  padding: var(--container-padding);
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms ease, visibility 300ms ease;

  &.-visible {
    opacity: 1;
    visibility: visible;
  }
}

.Modal__back {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--blue);
  opacity: 0.5;
}

.Modal__content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 230px;
  min-width: 100%;
  max-width: 100%;
  padding: 30px var(--container-padding);
  background: var(--grey-light);
  border-radius: 6px;
  color: var(--blue);
  font-size: 18px;
  font-weight: bold;
  text-align: center;

  @include breakpoint(tablet) {
    min-width: 480px;
  }
}

.Modal__close {
  @include clear-button();

  position: absolute;
  top: 0;
  right: 0;
  width: 35px;
  height: 35px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 17px;
    width: 1px;
    background-color: var(--blue);
  }

  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

.Modal__content__wrap {
  width: 100%;
}

.Modal__img {
  display: block;
  max-width: 100%;
  width: 180px;
  margin: auto;
  margin-bottom: 15px;
}
.Modal__text {
  max-width: 250px;
  margin: auto;
}

.Modal__message {
  color: var(--grey);
  font-weight: 300;
  font-size: 12px;
  margin-top: 10px;
}
