@import './tools';

:root {
  --blue: #312469;

  --pink: #d5006f;
  --pink-lighten: #f7c2dd;
  --pink-light: #ec96c3;
  --pink-medium: #b80360;
  --pink-darken: #a10154;

  --red: #fc3d3d;
  --red-lighten: #f5d1d1;

  --grey: #818181;
  --grey-light: #f8f6f6;
  --grey-medium: #c4c4c4;

  --container: 1024px;
  --container-padding: 17px;

  @include breakpoint(tablet) {
    --container-padding: 30px;
  }
}

html {
  height: 100%;
  color: var(--black);
}

body {
  font-family: 'helvetica', 'arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  min-height: 100%;
  font-size: 16px;
}

input,
textarea,
button {
  font-family: 'helvetica', 'arial', sans-serif;
  -webkit-font-smoothing: antialiased;
}

/*---------- xxxxx  ----------*/
// @font-face {
//   font-family: 'xxxxx';
//   src: url('/static/fonts/xxxxx/xxxxx-Regular.woff2') format('woff2'),
//       url('/static/fonts/xxxxx/xxxxx-Regular.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
// }
