@import './src/styles/tools';

.InputDate {
  margin-bottom: 21px;
}

.InputDate__group {
  position: relative;
}

.InputDate__label {
  margin-bottom: 9px;
  display: block;
  font-size: 15px;
  color: var(--blue);
}

.InputDate__fieldGroup {
  position: relative;
  display: block;
}

.InputDate__iconGroup {
  display: flex;
  border-radius: 6px;
  overflow: hidden;
}

.InputDate__icon {
  @include inputIcon();

  .InputDate.-disabled & {
    background-color: var(--grey);
  }
}

.InputDate__field--wrap {
  width: 100%;

  & > .react-datepicker-wrapper {
    width: 100%;
  }
}

.InputDate__field {
  @include input();

  &.-password {
    letter-spacing: 2px;
    padding: 12px 40px 12px 10px;
  }
}

.InputDate__passwordButton {
  @include clear-button();
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 40px;
}

.InputDate__passwordButton__svg {
  display: block;
  margin: auto;
  &.-text .svg__eye__background {
    fill: var(--blue);
  }
}

.InputDate__error {
  display: block;
  padding-top: 5px;
  color: var(--red);
  font-size: 15px;
}
