@import './src/styles/tools';

/* Expand */
.Expand {
  height: 0;
  overflow: hidden;
}

.Expand__children {
  padding: 1px;
  margin: -1px;
}

/* ExpandLabel */

.ExpandLabel {
  margin-bottom: 10px;
}

.ExpandLabel__button {
  @include clear-button();

  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  align-items: center;
}

.ExpandLabel__label {
  position: relative;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  min-height: 28px;
  min-width: 0;
}

.ExpandLabel__label__text {
  position: relative;
  // flex: 1 0 auto;
  font-size: 24px;
  color: var(--blue);
  margin-right: 10px;
  // overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;
  padding-right: 10px;
}

.ExpandLabel__label__hr {
  flex: 1 0 auto;
  // position: absolute;
  // top: 50%;
  // left: 0px;
  // width: calc(100% - 25px);
  width: auto;
  height: 1px;
  background-color: var(--grey-medium);
  margin-right: 30px;
}

.ExpandLabel__status {
  position: absolute;
  top: 5px;
  right: 0;
  width: 20px;
  height: 20px;
  background: url('/img/arrow.svg') no-repeat center;
  background-size: contain;
  transform: rotateZ(90deg);
  transition: transform 300ms ease;

  &.-open {
    transform: rotateZ(-90deg);
  }
}
