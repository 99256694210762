@import './src/styles/tools';

.FileDoc {
  margin-bottom: 21px;

  &.-disabled {
    opacity: 0.5;
  }
}

.FileDoc__label {
  display: flex;
  margin-bottom: 8px;
  align-items: center;
  font-size: 15px;
  color: var(--blue);
}

.FileDoc__group {
  display: flex;
  justify-content: space-between;
}

.FileDoc__input {
  position: relative;
  margin-right: 20px;
}

.FileDoc__input__field {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  max-width: 100%;
  opacity: 0;
  font-size: 0.1px;
}

.FileDoc__input__button {
  position: relative;
  display: block;
  width: 74px;
  height: 74px;
  border-radius: 50%;
  background: var(--grey-light);
  cursor: pointer;

  &.-white {
    background: #fff;
  }

  &.-error {
    background-color: var(--red-lighten);
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 20px;
    width: 1px;
    background-color: var(--blue);
    transform: translate(-50%, -50%);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-90deg);
  }

  input:focus-visible ~ & {
    outline: var(--blue) solid 2px;
    outline-offset: 2px;
    outline-style: auto;
  }

  .FileDoc.-disabled & {
    cursor: default;
  }
}

.FileDoc__attachment {
  display: block;
  min-width: 0;
  margin-left: auto;
  color: var(--blue);
}

.FileDoc__attachment__item {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: bold;
  text-align: right;
  width: 100%;
  margin-bottom: 7px;

  &::before {
    content: '';
    flex: 0 0 26px;
    width: 26px;
    height: 12px;
    background: url('/styles/inputIcons/paperclip.png') no-repeat center;
    background-size: contain;
    margin-right: 7px;
  }
}

.FileDoc__attachment__item__label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.FileDoc__attachment__item__remove {
  @include clear-button();

  flex: 0 0 18px;
  position: relative;
  width: 18px;
  height: 18px;
  margin-left: 8px;
  border-radius: 50%;
  background: var(--red);

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 9px;
    width: 1px;
    background-color: #fff;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  .FileDoc.-disabled & {
    display: none;
  }
}

.FileDoc__attachment__item__loading {
  flex: 0 0 18px;
  position: relative;
  width: 18px;
  height: 18px;
  margin-left: 8px;
  border-radius: 50%;
  background: var(--grey-medium);
}

.FileDoc__info {
  display: block;
  border-top: 1px solid var(--grey-medium);
  padding-top: 5px;
  margin-top: 10px;
  font-size: 15px;
  color: var(--pink);
}

.FileDoc__error {
  display: block;
  padding-top: 5px;
  color: var(--red);
  font-size: 15px;
}

.FileDoc__toolarge__alert {
  display: block;
  font-size: 30px;
  margin-bottom: 20px;
  margin-top: -30px;
}

.FileDoc__toolarge__title {
  margin-bottom: 10px;
}

.FileDoc__toolarge__info {
  font-weight: normal;
}
