@import './src/styles/tools';

.Textarea {
  margin-bottom: 21px;
}

.Textarea__group {
  position: relative;
}

.Textarea__label {
  margin-bottom: 9px;
  display: block;
  font-size: 15px;
  color: var(--blue);
}

.Textarea__fieldGroup {
  position: relative;
  display: block;
}

.Textarea__iconGroup {
  display: flex;
  border-radius: 6px;
  overflow: hidden;
}

.Textarea__icon {
  @include inputIcon();

  .Textarea.-disabled & {
    background-color: var(--grey);
  }
}

.Textarea__field {
  @include input();

  &.-password {
    letter-spacing: 2px;
  }
}

.Textarea__passwordButton {
  @include clear-button();
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 40px;
}

.Textarea__passwordButton__svg {
  display: block;
  margin: auto;
  &.-text .svg__eye__background {
    fill: var(--blue);
  }
}

.Textarea__error {
  display: block;
  padding-top: 5px;
  color: var(--red);
  font-size: 15px;
}
