@import './src/styles/tools';

.Button {
  text-align: center;

  &[data-no-center='true'] {
    text-align: left;
  }
}

.Button__button {
  @include button();

  position: relative;
  min-width: 270px;
}

.Button__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: all 300ms ease;

  &[data-visible='true'] {
    visibility: visible;
    opacity: 1;
  }
}

.Button__loading__svg {
  width: auto;
  height: 30px;

  path {
    fill: #fff;
  }
}
