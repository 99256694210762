@import './src/styles/tools';

.Page404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  min-height: 100vh;
}

.Page404__left {
  margin: 0 auto 0 0px;
}

.Page404__back {
  display: block;
  height: 40px;
  width: 40px;
  margin: 5px;
  background: url('/img/arrow.svg') no-repeat center;
  background-size: 10px;
  transform: rotateZ(180deg);
}

.Page404__content {
  @include container();
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.Page404__logo {
  display: block;
  margin: 0 auto;
  margin-bottom: 43px;
  width: 100%;
  max-width: 350px;
  height: auto;
}

.Page404__title {
  margin-bottom: 4px;
  font-size: 25px;
  text-align: center;
  color: var(--blue);
}

.Page404__text {
  margin: 0 auto;
  max-width: 270px;
  font-size: 15px;
  text-align: center;
  color: var(--blue);
}

.Page404__plug {
  flex: 1 0 50%;
  width: 100%;
  max-height: 500px;
}

.Page404__link {
  @include button();
  margin-bottom: 30px;
}

.Page404__footer {
  background-color: var(--grey-light);
  width: 100%;
  height: 57px;
}
