@import './src/styles/tools';

.Text {
  margin-bottom: 7px;
  font-size: 15px;
  line-height: 1.33;
  color: var(--blue);

  &.-grey {
    color: var(--grey-medium);
  }
  &.-capitalize {
    text-transform: capitalize;
  }
}
