@import './src/styles/tools';

.FileImg {
  margin-bottom: 21px;

  &.-disabled {
    opacity: 0.5;
  }
}

.FileImg__label {
  display: flex;
  margin-bottom: 8px;
  align-items: center;
  font-size: 15px;
  color: var(--blue);

  &::before {
    content: '';
    display: block;
    width: 26px;
    height: 12px;
    background: url('/styles/inputIcons/paperclip.png') no-repeat center;
    background-size: contain;
    margin-right: 7px;
  }
}

.FileImg__group {
  display: flex;
}

.FileImg__item {
  position: relative;
  background: var(--grey);
  width: 74px;
  height: 74px;
  margin-right: 20px;
  border-radius: 50%;
}

.FileImg__item__img {
  display: block;
  width: 74px;
  height: 74px;
  border-radius: 50%;
}

.FileImg__item__remove {
  @include clear-button();

  position: absolute;
  top: 0;
  right: 0;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: var(--red);

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 9px;
    width: 1px;
    background-color: #fff;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  .FileImg.-disabled & {
    display: none;
  }
}

.FileImg__item__loading {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba($color: #000000, $alpha: 0.5);
}

.FileImg__input {
  position: relative;
}

.FileImg__input__field {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  max-width: 100%;
  opacity: 0;
  font-size: 0.1px;
}

.FileImg__input__button {
  position: relative;
  display: block;
  width: 74px;
  height: 74px;
  border-radius: 50%;
  background: var(--grey-light);
  cursor: pointer;

  &.-white {
    background: #fff;
  }

  &.-error {
    background-color: var(--red-lighten);
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 20px;
    width: 1px;
    background-color: var(--blue);
    transform: translate(-50%, -50%);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-90deg);
  }

  input:focus-visible ~ & {
    outline: var(--blue) solid 2px;
    outline-offset: 2px;
    outline-style: auto;
  }

  .FileImg.-disabled & {
    cursor: default;
  }
}

.FileImg__toolarge__alert {
  display: block;
  font-size: 30px;
  margin-bottom: 20px;
  margin-top: -30px;
}

.FileImg__toolarge__title {
  margin-bottom: 10px;
}

.FileImg__toolarge__info {
  font-weight: normal;
}
