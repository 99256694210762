@import './src/styles/tools';

.Select {
  margin-bottom: 21px;
}

.Select__group {
  position: relative;
}

.Select__label {
  margin-bottom: 9px;
  display: block;
  font-size: 15px;
  color: var(--blue);
}

.Select__fieldGroup {
  position: relative;
  display: flex;
  border-radius: 6px;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 30px;
    background: url('/img/arrow.svg') no-repeat center;
    background-size: 35%;
    transform: rotateZ(90deg);
    pointer-events: none;
  }
}

.Select__icon {
  @include inputIcon();

  .Select.-disabled & {
    background-color: var(--grey);
  }
}

.Select__field {
  @include input();

  padding-right: 28px;
}

.Select__passwordButton {
  @include clear-button();
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 40px;
}

.Select__passwordButton__svg {
  display: block;
  margin: auto;
  &.-text .svg__eye__background {
    fill: var(--blue);
  }
}

.Select__error {
  display: block;
  padding-top: 5px;
  color: var(--red);
  font-size: 15px;
}
