@import './src/styles/tools';

.FolderList {
  color: var(--blue);
}

.FolderList__loading {
  position: relative;
  height: 100px;
  background-color: var(--grey-light);
  border-radius: 5px;
}

.FolderList__empty {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100px;
  background-color: var(--grey-light);
  border-radius: 5px;
  color: var(--grey);
}

.FolderList__error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100px;
  background-color: var(--red-lighten);
  border-radius: 5px;
  color: var(--red);

  span {
    display: block;
    color: grey;
    font-size: 12px;
  }
}
