.App {
}

.App__notready {
  z-index: 99999999;
  position: fixed;
  width: 100vw;
  height: 100vh;
  visibility: hidden;
  opacity: 0;
  transition: all 300ms ease 500ms;
  background: #fff;

  &[data-visible='true'] {
    visibility: visible;
    opacity: 1;
  }
}

.App__loading {
  z-index: 999999;
  position: fixed;
  width: 100vw;
  height: 100vh;
  visibility: hidden;
  opacity: 0;
  transition: all 300ms ease;

  &[data-visible='true'] {
    visibility: visible;
    opacity: 1;
  }
}
