@import './src/styles/tools';

.Checkbox {
  margin-bottom: 21px;
}

.Checkbox__fields {
  display: flex;
  align-items: center;
}

.Checkbox__field {
  // @include input();
  position: absolute;
  left: -100vw;
  visibility: hidden;
}

.Checkbox__status {
  display: block;
  flex: 0 0 29px;
  margin-right: 10px;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  border: 1px solid var(--blue);

  .Checkbox__field:checked ~ &::after {
    content: '';
    display: block;
    margin: 6px 0px 0px 5px;
    width: 17px;
    height: 10px;
    border-bottom: 1px solid var(--blue);
    border-left: 1px solid var(--blue);
    transform: rotate(-45deg);
  }
}

.Checkbox__label {
  display: block;
  font-size: 15px;
  font-weight: bold;
  color: var(--blue);
}

.Checkbox__error {
  display: block;
  padding-top: 5px;
  color: var(--red);
  font-size: 15px;
}
