@import './src/styles/tools';

.FolderItem {
  margin-bottom: 3px;
  padding: 5px 0 7px 0;
  border-bottom: 1px solid var(--grey-medium);
}

.FolderItem__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FolderItem__header__photo {
  padding-right: 20px;
  div {
    width: 74px;
    height: 74px;
    border-radius: 50%;
    background: var(--grey-light) url('/img/camera.svg') no-repeat center;
    background-size: 40%;
  }
  img {
    width: 74px;
    height: 74px;
    object-fit: cover;
    border-radius: 50%;
  }
}

.FolderItem__header__group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 20px;
  width: 100%;

  @include breakpoint(mobile, tablet) {
    .FolderItem.-photo & {
      display: block;
    }
  }
}

.FolderItem__header__title {
  font-size: 15px;
  font-weight: bold;
}

.FolderItem__header__notification {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: -4px 0px -4px -4px;
  background: url('/img/notification.svg') no-repeat center;
  background-size: contain;
}

.FolderItem__header__statusLabel {
  font-size: 14px;
  color: var(--pink);
}

.FolderItem__header__timeInfo {
  font-size: 14px;
  color: var(--pink);

  &::before {
    content: '';
    display: inline-block;
    margin-bottom: -1px;
    margin-right: 5px;
    width: 12px;
    height: 12px;
    background: url('/img/renew.png') no-repeat center;
    background-size: contain;
  }
}

.FolderItem__header__name {
  font-size: 14px;
}

.FolderItem__header__date {
  font-size: 14px;
  color: var(--grey-medium);
}

.FolderItem__header__more {
  @include clear-button();
  @include link-more();
}

.FolderItem__content {
  position: relative;
  min-height: 100px;
  padding: var(--container-padding);
  border-radius: 6px;
  background-color: var(--grey-light);
  margin: 10px 0;
}
