@import './src/styles/tools';

.MemberContact {
  @include container();

  padding-top: 15px;
  padding-bottom: 15px;
}

/* header */
.MemberContact__header {
  margin-bottom: 20px;
}

.MemberContact__header__title {
  @include title();
}

.MemberContact__content {
}
