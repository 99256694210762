@import './src/styles/tools';

.Input {
  margin-bottom: 21px;
}

.Input__group {
  position: relative;
}

.Input__label {
  margin-bottom: 9px;
  display: block;
  font-size: 15px;
  color: var(--blue);
}

.Input__fieldGroup {
  position: relative;
  display: block;
}

.Input__iconGroup {
  display: flex;
  border-radius: 6px;
  overflow: hidden;
}

.Input__icon {
  @include inputIcon();

  .Input.-disabled & {
    background-color: var(--grey);
  }
}

.Input__field {
  @include input();

  &.-password {
    letter-spacing: 2px;
    padding: 12px 40px 12px 10px;
  }
}

.Input__passwordButton {
  @include clear-button();
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 40px;
}

.Input__passwordButton__svg {
  display: block;
  margin: auto;
  &.-text .svg__eye__background {
    fill: var(--blue);
  }
}

.Input__description {
  display: block;
  font-size: 15px;
  color: var(--blue);
  margin-top: 5px;
}

.Input__error {
  display: block;
  padding-top: 5px;
  color: var(--red);
  font-size: 15px;
}
