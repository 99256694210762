@import './src/styles/tools';

.AdminMembers {
  @include container();

  padding-top: 15px;
}

.AdminMembers__title {
  margin-bottom: 13px;
  color: var(--blue);
  font-size: 24px;
  text-align: center;
}

/* search input */
.AdminMembers__search {
  position: relative;
  margin-bottom: 10px;

  &::before {
    content: '';
    position: absolute;
    top: 7px;
    left: 8px;
    width: 13px;
    height: 13px;
    background: url('/img/search.svg') no-repeat center;
    background-size: contain;
  }
}

.AdminMembers__search__input {
  @include input();

  padding: 5px 5px 5px 30px;

  &::placeholder {
    color: var(--blue);
  }
}
