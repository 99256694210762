@import './src/styles/tools';

.DetailMemberAdmin {
}

.DetailMemberAdmin__title {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 7px;
  text-transform: capitalize;
}

.DetailMemberAdmin__group {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.DetailMemberAdmin__group__item {
  margin: 0 20px;
  width: 100px;
  text-align: center;
  color: var(--blue);
  font-size: 18px;
  font-weight: bold;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 50px;
    height: 50px;
    font-size: 22px;
    background-color: #fff;
    border-radius: 50%;
    font-weight: 400;
  }
}

.DetailMemberAdmin__item {
  margin: 0 20px;
  max-width: 230px;
  margin: auto;
  text-align: center;
  color: var(--blue);
  font-size: 18px;
  font-weight: bold;

  span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 114px;
    height: 114px;
    // font-size: 22px;
    background-color: #fff;
    border-radius: 50%;
    color: var(--pink);
    font-weight: 400;

    svg {
      margin-bottom: 8px;
    }
  }
}
