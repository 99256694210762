@import './src/styles/tools';

.MemberSubscribe {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}

.MemberSubscribe__header {
}

.MemberSubscribe__logo {
  display: block;
  margin: 40px auto 60px auto;
  width: 100%;
  max-width: 350px;
  height: auto;
}

.MemberSubscribe__content {
  @include container();

  padding-top: 15px;
  padding-bottom: 25px;
}

.MemberSubscribe__footer {
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--grey-light);
  height: 57px;
  margin: auto 0 0 0;
  font-size: 18px;
  color: var(--blue);
}
