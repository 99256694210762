html,
body,
ol,
pre,
form,
fieldset,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

div,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
input,
textarea,
p {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
address {
  font-size: 100%;
  font-weight: normal;
  font-style: normal;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img {
  border: 0;
}

ol,
ul {
  list-style: none;
}

.clear {
  clear: both;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
