@import './src/styles/tools';

.MemberFolders {
  @include container();

  padding-top: 15px;
  padding-bottom: 15px;
}

/* header */
.MemberFolders__header {
  margin-bottom: 20px;
}

.MemberFolders__header__title {
  @include title();
}

/* navigation */
.MemberFolders__header__nav {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 20px 0px;
  grid-template-areas:
    '. .'
    '. .';
  margin-bottom: 20px;

  @include breakpoint(phablet) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 20px 0px;
    grid-template-areas: '. . . .';
  }

  @media all and (-ms-high-contrast: none) {
    .grid-container {
      display: -ms-grid;
      -ms-grid-columns: 1fr 1fr 1fr 1fr;
      -ms-grid-rows: 1fr;
      gap: 20px 0px;
    }
  }
}

.MemberFolders__header__nav__list {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* search input */
.MemberFolders__header__search {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 7px;
    left: 8px;
    width: 13px;
    height: 13px;
    background: url('/img/search.svg') no-repeat center;
    background-size: contain;
  }
}

.MemberFolders__header__search__input {
  @include input();

  padding: 5px 5px 5px 30px;

  &::placeholder {
    color: var(--blue);
  }
}
